function gmap(conf) {
    this._markers = conf.markers || [];
    this.zoom = 14;
    this.url = conf.url || null;
    this.mapContainer = conf.mapContainer || '';
    this.mapCenter = conf.mapCenter || null;
    this.map = null;
    if(this._markers.length > 0) {
        this.show();
    }
}

gmap.prototype.init = function() {
    if(this.gmapId != '' && typeof google == 'object' && typeof google.maps == 'object') {
        var mapOptions = {
            zoom: this.zoom,
            navigationControl: true,
            mapTypeControl: true,
            scaleControl: true,
            draggable: true,
            disableDoubleClickZoom: true,
            scrollwheel: true,
            zoomControl: true,
            //center: new google.maps.LatLng(41.837353,23.488663)
        }
        if(this.mapCenter) {
            mapOptions.push({center: this.mapCenter});
        }
        this.map = new google.maps.Map(document.getElementById(this.mapContainer), mapOptions);

        //google.maps.event.addDomListener(window, 'load', initialize);
        this._get();
    }
}
gmap.prototype._get = function() {
    var $this = this;
    if(this.url != null && this._markers.length == 0) {
        jQuery.getJSON(this.url, function(json) {
           $this._markers = json;
           $this.addMarkers();
        });
    } else {
        $this.addMarkers();
    }
}
gmap.prototype.addMarkers = function() {
    var bounds = new google.maps.LatLngBounds();
    var $this = this;

    for(var i = 0; i < $this._markers.length; i++) {
        var myLatlng = new google.maps.LatLng($this._markers[i].lat,$this._markers[i].lng);
        var marker = new google.maps.Marker({
            position: myLatlng,
            map: $this.map,
            title: $this._markers[i].title
        });
        bounds.extend(myLatlng);
    }

    this.map.fitBounds(bounds);
}
gmap.prototype.show = function() {
    google.maps.event.addDomListener(window, 'load', this.init());
}