function getPopularPlaces(type) {
    $('#popular .tab-pane').removeClass('active');
    $('#'+type).addClass('active');
}

function getPreloader() {
    return '<div class="preloader"><img src="/img/globe_48cfad_64px_gif_2.gif" /></div>';
}

$(function() {
    $('[data-toggle="tooltip"]').tooltip({html:true});
    $('.ratingfa').raty({
        size: 18,
        half: true,
        targetType: 'score',
        halfShow : true,
        readOnly: true,
        score: function() {
            return $(this).attr('data-score');
        }
    });

    $('[data-more-profiles]').click(function() {
        var cls = $($(this)).data('more-profiles');

        $('.'+cls).removeClass('hide');
        $(this).addClass('hide');
    });

    $('form#comment .ratingfa').raty({
        readOnly: false,
        size: 18,
        half: true,
        targetType: 'score',
        halfShow : true,
        score: function() {
            return $(this).attr('data-score');
        }
    });

    $('form#comment').submit(function(e) {
        e.preventDefault();

        $.post('/place/vote', $('form#comment').serialize(), function(response){
            location.reload();
        }, 'json');

    });
    var ajaxRequest;
    $('#invite-friend-form input[name="name"]').keyup(function(){
        var form = $('#invite-friend-form');

        if(ajaxRequest) {
            ajaxRequest.abort();
        }
        ajaxRequest = $.post(form.attr('action'), form.serialize(), function(response){
            $('#invite-friends-hldr').html(response.html)
        });

    });

    $('[data-show-map]').click(function(){
        var tripMap = $('#trip-map');
        if(tripMap.is(":visible")) {
            tripMap.hide();
            $(this).toggleClass('opened', false)
        } else {
            tripMap.show();
            $(this).toggleClass('opened', true);
            if(typeof gmap != 'undefined') {
                gmap.show();
            }
        }
    });

    $(document).on('click', '#invite-friends-hldr a', function() {
       var $this = $(this),
           url = $('#invite-friends-hldr').data('url'),
           username = $(this).data('username'),
           id = $('#invite-friends-hldr').data('id');

        $.post(url, {_token: $("input[name=_token]").val(), id: id, username: username}, function(response) {
            if(response.error == 0 || json.error == false) {
                $('.invited-stats .stats span:first').html(response.invited);
                if(response.profile != '') {
                    $('.invited-users').append(response.profile);
                }
                $this.parent().remove();
            }
        }, 'json');
    });

});