/**
 * Created by niki on 27.09.15.
 */

var user = {
};

user.addFavorites = function(tripId, container){
    $.post('/profile/addFavorites/' + tripId, { _token: $("input[name=_token]").val()}, function(response) {
        $(container).html(response.html);
    });
};

user.deleteFavorites = function(id, container) {
    $.post('/profile/deleteFavorites/' + id, { _token: $("input[name=_token]").val()}, function(response) {
        if(container) {
            $(container).html(response.html);
        } else {
            window.location.reload();
        }
    });
};
user.addVisited = function(formId) {
    var form = $(formId),
        id = form.data('id'),
        container = form.data('container');

    $.post('/profile/addVisitedPlace/' + id, form.serialize(), function(response) {
        if(container) {
            $(container).html(response.html);
        } else {
            window.location.reload();
        }
    });

};
user.setVisited = function(id, container) {
    $.post('/profile/addVisitedPlace/' + id, { _token: $("input[name=_token]").val()}, function(response) {
        if(container) {
            $(container).html(response.html);
        } else {
            window.location.reload();
        }
    });
};
user.removeVisited = function(id, container) {
    $.post('/profile/removeVisitedPlace/' + id, { _token: $("input[name=_token]").val()}, function(response) {
        if(container) {
            $(container).html(response.html);
        } else {
            window.location.reload();
        }
    });
};


$(function(){

    $.fn.datepicker.defaults.format = "dd/mm/yyyy";
    $('.datepicker').datepicker({
        autoclose: true,
        language: $('[data-datapicker-language]').data('datapicker-language')
    });

    $('body').on('click', '[data-add-favorites]', function(e) {
        e.preventDefault();
       var id = $(this).data('add-favorites');
        user.addFavorites(id, $(this).data('container'));
    });

    $('body').on('click', '[data-delete-favorites]', function(e) {
        e.preventDefault();
        var id = $(this).data('delete-favorites'),
            container = $(this).data('container') || false;
        user.deleteFavorites(id, container);
    });

    $(document).on('click', '[data-remove-visited-place]', function(e){
        e.preventDefault();
       user.removeVisited($(this).data('remove-visited-place'), $(this).data('container'));
    });
    $(document).on('click', '[data-set-visited-place]', function(e){
        e.preventDefault();
       user.setVisited($(this).data('set-visited-place'), $(this).data('container'));
    });
    $(document).on('click', '[data-visited-place-save]', function(e){
        e.preventDefault();
        $('.modal-backdrop').remove();//fix
        user.addVisited($(this).data('visited-place-save'));
    });

    $('input[name=is_visited]').click(function(){
        if($(this).is(":checked")) {
            $("#visited-info").removeClass('hidden');
        }else {
            $("#visited-info").addClass('hidden');
        }
    });

});

function timeline() {

}

timeline.prototype.get = function() {

};